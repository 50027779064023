body {
  background-color: #000000;
}

.grid {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 15px;
}

.box {
  width: auto;
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.icon {
  font-size: 35px;
  color: #ffffff;
}
